import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { TwitterTweetEmbed } from "react-twitter-embed";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Design1 from "../assets/img/design1.png";
import Design2 from "../assets/img/design2.png";
import globalStyle from "../utils/globalStyle";

const style = {
  gameContainer: {
    display: "flex",
    justifyContent: "center",
    // ...globalStyle.sectionPadding,
    padding: "50px 0",
  },
  gameIFrame: {
    height: 1044,
    width: 1024,
  },
  design1: {
    display: {
      xs: "none",
      md: "block",
    },
    height: 280,
  },
  design2: {
    display: {
      xs: "none",
      md: "block",
    },
    height: 180,
    alignSelf: "flex-end",
  },
  headerTitle: {
    background: (theme) => theme.palette.secondary.main,
    padding: 5,
  },
  headerText: {
    textAlign: "center",
    color: (theme) => theme.palette.common.white,
    fontSize: 42,
  },
  tweetsContainer: {
    ...globalStyle.sectionPadding,
  },
};
export default function Home() {
  return (
    <Box>
      <Header />
      <Box sx={style.gameContainer}>
        <Box
          component="img"
          src={Design1}
          alt="design1"
          sx={style.design1}
        />

        <Box
          component="iframe"
          frameborder="0"
          src="https://itch.io/embed-upload/4689849?color=333333"
          allowfullscreen=""
          sx={style.gameIFrame}
        >
          <a href="https://txstable.itch.io/super-schrodinger">
            Play Super Schrodinger on itch.io
          </a>
        </Box>
        <Box component="img" src={Design2} alt="design2" sx={style.design2} />
      </Box>

      <Box sx={style.headerTitle}>
        <Typography variant="h1" sx={style.headerText}>
          Inspiration
        </Typography>
      </Box>

      <Grid container sx={style.tweetsContainer} spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TwitterTweetEmbed
            tweetId="1122660604953542658"
            options={{
              cards: "hidden",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TwitterTweetEmbed
            tweetId="1017949146366476289"
            options={{
              cards: "hidden",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TwitterTweetEmbed
            tweetId="1122672146944159744"
            options={{
              cards: "hidden",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TwitterTweetEmbed
            tweetId="1122676953071276033"
            options={{
              cards: "hidden",
            }}
          />
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}
